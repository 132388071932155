/* typewriter.css */
@keyframes typewriter {
    from {
      width: 0;
    }
  }
  
  .typewriter {
    overflow: hidden;
    white-space: nowrap;
    animation: typewriter 3s steps(40, end);
  }
  